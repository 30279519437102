.business-cases-page{
    .section{
        margin: 80px auto;
        >.title{
            font-size: 28px;
            margin-bottom: 60px;
            text-align: center;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
    }
    .page-banner{
        height: 546px;
        margin-top: 0 !important;
        position: relative;
        .banner-text{
            color: #fff;
            margin-top: -30px;
            .main-text{
                font-size: 72px;               
            }
            .sub-text{
                font-size: 28px;
            }
        }
    }
    .cases-by-industry{
        .case-list{
            display: flex;
            .case-item{
                background:#F4F5F9;
                width: 382px;
                height: 450px;
                margin-right: 27px;
                text-align: center;
                position: relative;
                border-radius: 5px;
                &:nth-child(3n){
                    margin-right: 0;
                }
                .case-image{
                    height: 346px;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                    // width:106px;
                    height: 355px;
                    }
                  
                }
                .logo-image{
                    width: 96px;
                    height: 96px;
                    border-radius: 48px;
                    position: relative;
                    top: -48px;
                    background: #FFF;
                    box-shadow: 0px 0px 18px 0px rgba(83, 83, 96, 0.28);
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                    width:106px;
                    height: 106px;
                    }
                }
                .case-name{
                    position: absolute;
                    width: 100%;
                    bottom: 26px;
                }
                .highlight-segment{
                    width: 382px;
                    height: 2px;
                    background: #ffeb00; //#F8A158;
                    position: absolute;
                    bottom: 0;
                    left: 0;    
                    bottom: -28px;
                    left: 0;
                    z-index: 2;       
                    opacity: 0;  
                }
                &:hover{
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                    .highlight-segment{
                        opacity: 1;
                    }
                }

                
               
            }
        }
        .break-line{
            height: 2px;
            background: #DFDFDF;
            position: relative;
            margin-top: 26px;
            
        }
    }
    .newTitle{
        cursor:pointer;
        transition: all  0.3s;
        &:hover{
            color: #0654A1;
        }
    }
}
@primary-color: #0654A1;