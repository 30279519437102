.common-component{
    &.bottom-nav-section{
        height: 110px;
        background:url('./../../images/bottom-nav-bg.png') no-repeat center;
        background-size: cover;
        display: flex;
        cursor: pointer;
        align-items: center;
        text-align: center;
        font-size: 24px;
        color: #4E450C;
    }

    &.erweima{
        background: #FFF;
    }

    &.main-menu-item{
        // width: 120px;
        text-align: right;
        padding-right: 20px;
        position: relative;
        cursor: pointer;
        line-height: normal;
        .arrow-down{
            display: inline-block;
            border: 6px solid #000;
            border-color: transparent;
            border-top-color: #000;
            position: absolute;
            right: 0;
            bottom: 2px;
        }
    }
    
    &.prev-next-arrows{
        .arrow{
            position: absolute;
            top: 100px;
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 20px solid;
            border-color: transparent;
            cursor: pointer;
            &.pre-arrow{
                left: -60px;
                border-right-color: #ddd;
                
            }
            &.next-arrow{
                right: -60px;
                border-left-color: #ddd;
            }
        }
    }
    
}
@primary-color: #0654A1;