.app-lang-en{
    .contact-us{
        .contact-info{
            .contact-info-item{
                .title{
                    font-size: 22px !important;
                }
            }
        }
    }
}

.contact-us{
    .page-banner{
        height: 546px;
        position: relative;
        .banner-text{
            height: inherit;
            color: #fff;
            padding-top: 192px;
            font-size: 72px;
        }
    }
    .contact-info{
        padding: 60px 0 0;
        background: #F4F5F9;
        .contact-info-list{
            display: flex;

            .contact-info-item{
                height: 450px;
                border-radius: 5px;
                border: 1px solid #E8EEF3;
                &:hover{
                    // box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.28);
                }
                &.wechat-h5, &.wechat-app{
                    width: 336px;
                    margin-right: 31px;
                    padding: 42px 0;
                    text-align: center;
                    .title{
                        font-size: 28px;
                        padding: 0 55px;
                        margin-bottom: 36px;
                      
                    }
                    .qrcode-container{
                        width: 184px;
                        padding: 10px 12px;
                        // border: 1px dashed;
                        margin: 0 auto;
                        background: #fff;
                        img{
                            width: 160px;
                        }
                        .erweima{
                            padding: 0;
                        }
                    }
                    .note-text{
                        font-size: 14px;
                        margin-top: 20px;
                        padding: 0 40px;
                    }
                }
                &.wechat-h5{
                    .qrcode-container{
                        width: 184px;
                        padding: 0 2px;
                        img{
                            width: 180px;
                        }
                       
                    }
                }
                &.wechat-h5,&.other-contact-info{
                    background: #FFF;
                }
                &.wechat-app {
                    background: #F5B321;
                    color: #fff;
                }
                &.other-contact-info{
                    width: 466px;
                    // padding: 82px 74px;
                    padding: 70px 70px 70px ;
                    li{
                        // margin-bottom: 16px;
                        margin-bottom: 30px;
                        list-style: none;
                        .title{
                            margin-top: 10px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-size: auto 20px;
                            font-size: 20px;
                            padding-left: 22px;
                        }
                        &.client-service{
                            .title{
                                background-image: url('./../../../images/contact-info-client-service-icon.png');
                            }
                        }
                        &.business-cooperation{
                            .title{
                                background-image: url('./../../../images/contact-info-buz-coop-icon.png');
                            }
                        }
                        &.market-cooperation{
                            .title{
                                background-image: url('./../../../images/contact-info-market-coop-icon.png');
                            }
                        }
                        &.other-cooperation{
                            .title{
                                background-image: url('./../../../images/contact-info-other-coop-icon.png');
                            }
                        }
                        .info{
                            // font-size: 14px;
                            font-size: 16px;
                            color:#999999; 
                            padding-left: 22px;
                            padding-top: 10px;
                        }

                    }
                    
                }
                
            }
        }
    }

    .user-feedback{
        padding: 80px 0;
        background: #F4F5F9;      
        .title{
            font-size: 28px;
            text-align: center;
            margin-bottom: 48px;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
        .user-feedback-form-container{
            height: 520px;
            display: flex;
            background: #fff;
            border-radius: 5px;
            .img-container{  
                width: 480px;
                img{
                    height: 520px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    cursor: pointer;
                    transition: all 0.6s;
                   
                    &:hover{
                        width: 489px;
                        height:530px;
                      
                    }
                }           
               
           
                             
            }
            .form-container{
                // width: 720px;
                flex: 1;
                padding: 60px 117px;

                input, textarea{
                    margin-left: 5px;
                    width: 470px;
                    height: 44px;
                    background: #F5F5F5 !important;;
                    border-radius: 5px;
                    border: none;
                    font-size: 16px;
                    color: #444;  
                    display: inline-block;              
                    &:focus{
                        box-shadow: none;  
                    }
                }
                textarea{
                    height: 121px;
                }
                .submit-button{
                    background: #FFEB00;
                    color: #4F4909;
                    height: 50px;
                    border: none;
                    border-radius: 25px;
                    padding: 0 25px;
                    font-size: 18px;
                    width: 160px;
                    &:hover{
                        color: #0654a1;
                    }
                }
            }

        }
    }

    .company-address{
        
        padding: 80px 0;
        // height: 600px;
        .title{
            font-size: 28px;
            text-align: center;
            // margin-bottom: 60px;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
        .address-list{
            height: 500px;
            overflow: auto;
            position: relative;
            top: 30px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            box-shadow:none;
            .address-item{
                height: 410px;
                width: 470px;
                // padding: 50px 40px;
                padding: 100px 40px;
                box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                text-align: center;
                position: relative;
                &.China{
                    margin-right: 50px;
                    background: url('./../../../images/address-china-bg-icon.png') no-repeat bottom right;
                    background-size: auto 65%;
                    cursor: pointer;
                    transition: all 0.6s;
                    &:hover{
                        background-size: auto 85%;
                    }
                }
                &.Singapore{
                    background: url('./../../../images/address-singapore-bg-icon.png') no-repeat bottom right;
                    background-size: auto 65%;
                    cursor: pointer;
                    transition: all 0.6s;
                    &:hover{
                        background-size: auto 85%;
                    }
                }
                .address-title{
                    font-size: 26px;
                    margin-bottom: 18px;
                }
                .address-detail{
                    font-size: 18px;
                }
                .view-map{
                    position: absolute;
                    bottom: 70px;
                    left: 20px;
                    color: #005EAD;
                }
                // &:hover{
                //     box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.28);
                // }
            }
        }
    }
}

.map-modal{

    .ant-modal-body{
        padding: 30px 24px;
    }
    .ant-modal-close-x{
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

@primary-color: #0654A1;