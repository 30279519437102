// .app-lang-en{
//     .home-page{
//         .banner{
//             .banner-text{
//                 // top: 180px;
//                 .main-text{
//                     font-size: 60px;
                    
//                 }
//             }
//         }
//     }
// }

@titleFontSize: 28px; 

.banner-slide-container{
    height: 300px;
    .slide-item{
        height: inherit;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &.clickable{
            cursor: pointer;
        }
    }
}
.home-page{
    .banner{
        height: 700px;
        background: #eee;
        position: relative;
        .banner-text{
            z-index: 102;
            position: absolute;
            top: 420px;
            width: 100%;
            text-align: center;
            .main-text{
                font-size: 100px;
                color: #ffdb00;
                
            }
            .sub-text{
                font-size: @titleFontSize;
                color: #fff;
                margin-top: 16px;
                margin-bottom: 70px;
            }
            .input-container{
                width: 412px !important;
                margin: 0 auto;
                background: #FFF;
                border-radius: 32px;
                position: relative;
                input{
                    width: 412px !important;
                    height: 64px;
                    border-radius: 32px;
                    padding: 10px 32px;
                    font-size: 18px;
                    text-align: left;
                    background: transparent;
                    // background: url('./../../images/search-icon.png') no-repeat 20px center;
                    
                    // background-size: auto 50%;
                    &:hover{
                        border-color: #eee;
                    }
                    &:focus{
                        border-color: #EEE;
                        box-shadow: none;  
                    }    
                    
                    &::-webkit-input-placeholder {
                        color: #bbb;
                    }
                    &::-moz-input-placeholder {
                        color: #bbb;
                    }
                    &::-ms-input-placeholder {
                        color: #bbb;
                    }                 
                }
                .search-icon{
                    height: 22px;
                    width: auto;
                    position: absolute;
                    right: 20px;
                    top: 21px;
                    cursor: pointer;
                }
                .address-list{
                    position: absolute;
                    top: 64px;
                    width: 360px;
                    background: #fff; //#FFED80;
                    left: 26px;
                    box-shadow: 0px 0px 18px 0px rgba(83, 83, 96, 0.28);
                    padding: 6px 16px;
                    text-align: left;
                    height: 300px;
                    overflow-y: scroll;
                    .address-item{
                        padding: 6px 0;
                        cursor: pointer;
                    }
                }
            }
            
        }
    }
    .statistics{
        margin-top: -86px;
        .statistic-list{
            display: flex;
            justify-content: center;
            .statistic-item{
                width: 20%;
                height: 140px;
                margin-right: 24px;
                background: #FFF;
                box-shadow: 0px 0px 18px 0px rgba(83, 83, 96, 0.28);
                border-radius: 5px;
                &:last-child{
                    margin-right: 0;
                }
                z-index: 101;
                padding: 21px 10px;
                .value{
                    display: flex;
                    align-items: center;             
                    justify-content: center;   
                    .value-num{
                        color: #0054A1;
                        font-size: 40px;
                    }
                    .value-unit{
                        // margin-top: 10px;
                        margin-left: 6px;
                    }
                }
                .label{
                    text-align: center;
                }
            }
        }
        

    }
    .industry-scatter{
        .scatter-map{
            height: 700px;
            background: #eee;
            // margin: 80px 0;
        }
        .industry-detail{
            margin: 80px 0;
            margin-top: 0;
            position: relative;
           
            .flex-container{
                display: flex;
                .industry-desc{
                    background: rgba(255,232,0,0.7);
                    color: #373200;
                    height: 350px;
                    width: 192px;
                    padding: 20px 30px;
                    margin-right: 20px;
                    border-radius: 5px;
                    .title{
                        font-size: 22px;
                        
                       
                    }
                    .details{
                        font-size: 18px;;
                    }
    
                }
                .industry-image{
                    width: 316px;
                    height: 350px;
                    margin-right: 20px;
                    border: 1px solid #eee;
                    border-radius: 5px;
                    img{
                        width: 100%;
                        height: 350px;
                        border-radius: 5px;
                        cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                        width: 105%;
                        height: 370px;
                    }
                        
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                  
                }
            }
            .arrow{
                top: 150px;
            }

        }
    }
    .product-service, .professional-manage{
        padding: 80px 0;
        color: #444444;
        .title{
            font-size: @titleFontSize;
            text-align: center;
            margin-bottom: 60px;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
        .details{
            display: flex;
            .detail-item{
                width: 25%;
                text-align: center;
                img{
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    transition: all 0.3s;
                }
                img:hover{
                    width: 100px;
                    height: 100px;
                }
                .item-title{
                    font-size: 22px;
                    margin: 20px 0 8px;
                    
                }
                .item-desc{
                    font-size: 16px;
                    padding: 0 20px;
                }
            }
        }
    }
    .product-service{
        background: #F4F5F9;
        .details{
            .detail-item{
                .item-desc{
                    font-size: 16px;
                    padding: 0 20px;
                }
            }
        }
    }
    .professional-manage{   
        background:#F4F5F9;
        .details{
            margin-bottom: 40px;
            .detail-item{
                cursor: pointer;
                .item-active-border{
                    display: none;
                    width: 82px;
                    height: 4px;
                    border-radius: 2px;
                    background: #ffeb00;
                    margin: 0 auto;
                }
                &.selected{
                    .item-active-border{
                        display: block;
                    }
                }
            }
        }
        .active-detail-item-demo{
            display: flex;
            .demo-item{
                width: 25%;
                margin-right: 8px;
                &:nth-child(4n){
                    margin-right: 0;
                }
                img{
                    width: 100%;
                    height: 180px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                        
                        width: 101%;
                        height: 190px;
                    }
                }
                .desc-text{
                    padding: 10px 20px 0;
                    text-align: center;
                }
            }
        }
    }
    .map-image{
        .map-container{
            margin-top: 80px;
            height: 1000px;
            background-repeat: no-repeat;
            background-position: center;
            // background-size: cover;
            background-size: 100%;
            border-radius: 5px;
            .title{
                text-align: center;
                font-size: @titleFontSize;
                cursor:pointer;
                transition: all  0.3s;
                &:hover{
                    color: #0654A1;
                }
            }
        }
    }
    .business-partners{
        margin: 80px 0;
        .title{
            text-align: center;
            font-size: @titleFontSize;
            margin-bottom: 60px;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
        .partner-list-container{
            position: relative;
            height: 324px;
            .partner-list{
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-between;
                .partner-item{
                    width:210px;
                    height: 160px;
                    margin-right: 120px;
                    img{
                        cursor: pointer;
                        transition: all 0.3s;
                    }
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                }
                img{
                    display: block;
                    width: 210px;
                    height: 102px;
                    margin: 0 auto;
                    border-radius: 5px;
                    
                }
                img:hover{
                    width: 260px;
                    height: 125px;
                }
            }
            .arrow{
                top: 112px;
            }
            
        }
    }
}
.newTitle{
    cursor:pointer;
    transition: all  0.3s;
    &:hover{
        color: #0654A1;
    }
}

@primary-color: #0654A1;