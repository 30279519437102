@highlight: #0654a1; 
.warehouse-for-rent-page{
    .section{
        margin: 80px auto;
        >.title{
            font-size: 28px;
            margin-bottom: 60px;
            text-align: center;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
    }
    .page-banner{
        height: 546px;
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: cover;
        margin-top: 0 !important;
        position: relative;
        .banner-content{
            // display: flex;
            padding-top: 100px;
            // align-items: center;
            // justify-content: stretch;
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 100;
            .banner-text{
                width: 750px;
                color: #fff;
                margin-top: -20px;
                .main-text{
                    font-size: 72px;
                }
                .sub-text{
                    font-size: 28px;
                }
            }
            .erweima-container{
                width: 340px;
                height: 408px;
                padding: 54px 40px;
                background: #fff;
                float: right;
                border-radius: 5px;
                img{
                    width: 264px;
                }
                .common-component.erweima{
                    padding: 12px;
                    width: 264px;
                    height: 264px;
                    border: 1px dashed;
                    text-align: center;
                }
                .note-text{
                    font-size: 20px;
                    text-align: center;
                    margin-top: 14px;
                }
            }
        }
    }
    .warehouse-types{
        .type-list{
            display: flex;
            flex-wrap: wrap;
            .type-item{
                width: 382px;
                // height: 408px;
                border: 1px solid #E8EEF3;
                padding: 27px;
                color: #6B6B6B;
                margin-right: 27px;
                margin-bottom: 26px;
                text-align: center;
                border-radius: 5px;
                &:nth-child(3n){
                    margin-right: 0;
                }
                .type-size{
                    font-size: 24px;
                }
                img{
                    // height: 194px;
                    height: 215px;
                    // margin: 47px 0 64px;
                    margin:10px 0 10px;
                    cursor: pointer;
                    transition: all 0.3s;
                }
                &:hover{
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                   
                }
                img:hover{
                    width: 105%;
                    height: 230px;
                }

            }
        }
    }
    .rent-procedure{
        background: #F4F5F9; //#E2E6F5;
        height: 510px;
        padding-top: 40px;
        .procedure-steps{
            display: flex;
            .step{
                width: 25%;
                text-align: center;
                position: relative;
                img{
                    width: auto;
                    cursor: pointer;
                    transition: all 0.3s;
                }
                img:hover{
                    width: 70px;
                    height: 70px;
                }
                .step-icon{
                    height: 60px;
                    margin: 50px 0;
                }
                .step-arrow{
                    position: absolute;
                    height: 30px;
                    right: -16px;
                    top: 65px;
                }
                .step-text{
                    color: #eee;
                    font-size: 40px;
                }
                .step-desc{
                    font-size: 24px;
                    margin-top: -32px;
                }
            }
        }
    }
    .warehouse-available{
        .title-and-search{
            position: relative;
            display: flex;
            align-items: center;
            .placeholder{
                width: 400px;
            }
            .title{
                width: 400px;
                cursor:pointer;
                transition: all  0.3s;
                &:hover{
                    color: #0654A1;
                }
            }
            .search-container{
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .search-by-input{
                    margin-right: 5px;
                    .ant-input{
                        top: -6px;
                    }
                    .search-address{
                        width: 200px;
                        font-size: 14px;
                        // text-align: right;
                    }
                    input, img, .address-list{
                        z-index: 1001;      
                    }
                    input, .address-list{
                        width: 200px;
                    }
                    // .address-list{
                    //     // position: absolute; 
                    //     // top: 41px;
                    //     font-size: 14px;
                    //     background: rgb(136, 72, 72);
                    //     box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);            
                    //     padding: 6px 16px;
                    //     text-align: left;
                    //     height: 300px;
                    //     overflow-y: scroll;
                    //     z-index: 1001;
                    //     .address-item{                  
                    //         padding: 6px 0;
                    //         cursor: pointer;
                    //     }
                    // }
                    // .address-list-mask{
                    //     position: fixed;
                    //     width: 100%;
                    //     height: 100vh;
                    //     background: #FFF;
                    //     opacity: 0;
                    //     top: 0;
                    //     left: 0;
                    //     z-index: 1000;
                    // }
                    
                }
                
                .search-icon{
                    height: 20px;
                    width: auto;
                    // right: 0;
                    // top: 15px;
                    cursor: pointer;
                }
                .city-list-container{
                    position: relative;
                    font-size: 14px;
                    margin-left: 6px;
                    // width: 140px;
                    .arrow-down{
                        bottom: 1px;
                    }

                    .city{
                        cursor: pointer;
                        &:hover{
                            color: @highlight;
                            box-shadow: none;
                        }
                    }
                    // .city-list{
                    //     position: absolute;
                    //     height: 270px;
                    //     background: #fff;
                    //     overflow-y: scroll;
                    //     width: 500px;
                    //     right: 0;
                    //     top: 26px;
                    //     text-align: left;
                    //     padding: 5px 10px;
                    //     box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
                    //     .city{
                    //         cursor: pointer;
                    //         &:hover{
                    //             color: @highlight;
                    //         }
                    //     }
                    //     z-index: 1001;
                    // }
                    // .city-list-mask{
                    //     position: fixed;
                    //     width: 100%;
                    //     height: 100vh;
                    //     background: #FFF;
                    //     opacity: 0;
                    //     top: 0;
                    //     left: 0;
                    //     z-index: 1000;
                    // }
                    
      
               
                }
                
            }
            
            
        }
        .warehouse-list{
            display: flex;
            flex-wrap: wrap;
            .warehouse-item{
                width: 282px;
                // height: 300px;
                margin-bottom: 16px;
                margin-right: 24px;
                padding-bottom: 16px;
                border-radius: 5px;
                
                &:hover{
                    img{
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                        width: 300px;
                        height: 180px;
                    }
                }
                &:nth-child(4n){
                    margin-right: 0;
                }
                img{
                    height: 172px;
                    border-radius: 5px;        
                    cursor: pointer;
                transition: all 0.3s;    
                }
                .warehouse-name{
                    margin: 5px 0;
                    font-size: 19px;
                    font-weight: 500;
                }
                .warehouse-address{
                    margin: 5px 0 16px;
                    background: url('./../../images/address-icon.png') no-repeat left center;
                    background-size: auto 16px;
                    padding-left: 20px;
                    cursor: pointer;
                    &:hover{
                        color: @highlight;
                    }
                    
                }
                .get-store-qr-code-container{
                    position: relative;
                    // margin: 0 12px;
                    // text-align: center;
                    .button{
                        background: #FFED80;
                        border-radius: 22px;
                        height: 44px;
                        line-height: 44px;
                        display: inline-block;
                        padding: 0 35px;
                        cursor: pointer;
                        &:hover{
                            color: @highlight;
                        }
                    }
                    .qr-code-image{
                        position: absolute;
                        background: #FFF;
                        padding: 12px;
                        border-radius: 5px;
                        bottom: 60px;
                        left: -20px;
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                        img{
                            width: 282px;
                            height: 282px;
                            box-shadow: none
                        }
                        z-index: 1003;
                    }
                    .qr-code-message{
                        width: 100%;
                        // height: 50px;
                        margin:15px 0;
                        text-align: center;
                        // width: 100px;
                    }
                    .qr-code-image-mask{
                        position: fixed;
                        width: 100%;
                        height: 100vh;
                        background: #fff;
                        opacity: 0;
                        z-index: 1002;
                        top: 0;
                        left: 0;
                        pointer-events: none
                    }
                }
            }
        }
        .no-result{
            text-align: center;
            margin-bottom: 80px;
        }
        .get-more{
            text-align: center;
            margin-top: 30px;
            button{
                width: 112px;
                // height: 28px;
                   height: 37px;
                background: #F4F4F4;
                border-radius: 14px;
                font-size: 14px;
                border: none;
                cursor: pointer;
                &:hover{
                    color: @highlight;
                }
            }
        }
        
    }
}
.ant-modal-content{
    height: 500px;
    padding:10px;
    .ant-input{
        // width: 680px;
        // height: 40px;
        border-radius: 50px;
        // background: rgb(228, 228, 228);
        // margin-top: 20px;
    }
    .city-Modal{
        // width: 695px;
        width: 500px;
        height: 350px;
        margin-top: 20px;
        padding: 0 10px;
        .city-Table{
            width: 500px;
            height: 300px;
            margin: 10px 0;
            overflow-x:auto;
        }
        Button{
            &:hover{
                border-color:#d9d9d9;
            }
        }
    }
}
.all-City{
    font-weight: 600;
    color: #929292;
}
.address-list{
    position: absolute; 
    top: 100px;
    left: 30px;
    font-size: 14px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);            
    padding: 6px 16px;
    text-align: left;
    height: 300px;
    overflow-y: scroll;
    z-index: 1001;
    .address-item{                  
        padding: 6px 0;
        cursor: pointer;
    }
}
.address-list-mask{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #FFF;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1000;
}
.city-selected{
    font-size: 15px;
}
.newTitle{
    cursor:pointer !important;
    transition: all  0.3s !important;
    &:hover{
        color: #0654A1;
    }
}
@primary-color: #0654A1;