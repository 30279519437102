.join-us-page{
    .section{
        // margin: 80px auto;
        >.title, .section-title{
            font-size: 28px;
            text-align: center;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
    }
    .page-banner{
        height: 546px;
        position: relative;
        .banner-text{
            display: flex;
            align-items: center;
            height: inherit;
            .main-text{
                font-size: 72px;
                margin-top: -30px;
            }
        }
    }
    .join-us-who{
        background: #FFF;//#E2E6F5;
        padding-top: 80px;
        .sub-title{
         color: #6B6B6B;
         font-size: 18px;   
         padding: 26px 0;
         text-align: center;
        }
        .container{
            background-repeat: no-repeat;
            background-position: center top;
            background-size: auto 85%;
            position: relative;
            height: 535px;
            padding: 15px 0;
            text-align: center;
            .main-image{
                height: 80%;
                margin-top: 10%;
            }
            .join-us-who-item{
                position: absolute;
                background: #fff;
                border-radius: 5px;
                .flex-item{
                    display: flex;
                    align-items: center;
                    width: 360px;
                    // min-width: 300px;
                    // max-width: 360px;
                    height: 80px;
                    text-align: left;
                    border-radius: 5px;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);    
                    &:hover{
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);    
                    }
                    .img-container{
                        width: 80px;
                        text-align: center;
                        img {
                            height: 40px;
                            width: auto;
                            max-width: 40px;
                        }
                    }
                    .text-container{
                        width: 280px;
                        padding-right: 12px;
                        .main-text{
                            font-weight: bold;
                        }
                        .sub-text{
                            font-size: 14px;
                            margin-top: 4px;
                        }
                    }
                }
                
                &.item1{
                    top: 20%;
                    right: 61%;
                    
                }
                
                &.item2{
                    top: 53%;
                    right: 68%;
                    // width: 330px;
                    .flex-item{
                        // width: 330px;
                    }
                }
                &.item3{
                    top: 18%;
                    left: 64%;
                }
                &.item4{
                    top: 60%;
                    left: 62%;
                }
                &.item1, &.item2{
                    &::after{
                        content: "";
                        border: 10px solid #FFF;
                        border-right: none;
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                        position: absolute;
                        top: 8px;
                        right: -8px;
                    }
                }
                &.item3, &.item4{
                    &::after{
                        content: "";
                        border: 10px solid #FFF;
                        border-left: none;
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                        position: absolute;
                        top: 8px;
                        left: -8px;
                    }
                }
            }
        }
}
    .join-us-why{
        .mask{
            background: transparent;
            // background: rgba(0,0,0,0.4);
            color: #fff;
            height: 821px;
            .title{
                padding: 80px 0;
            }
            .highlights-list{
                display: flex;
                .highlight-category{
                    width: 16.66%;
                    border-bottom: 2px solid #e5e5e5;
                    padding: 5px 0 20px;
                    position: relative;
                    text-align: center;
                    cursor: pointer;
                    .highlight-category-active{
                        height: 2px;                  
                        width: 60%;
                        position: absolute;
                        bottom: -2px;
                        left: 20%;
                        background: #ffeb00; //#FFEB00;
                    }
                }           
            }
            .highlight-category-details{
                position: relative;
                .arrow{
                    top: 180px;
                }
                .detail-list{
                    height: 400px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin: 60px auto;
                    .detail-item{
                        width: 50%;
                        height: 360px;
                        background: #fff;
                        color: #444;
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.28);
                        border-radius: 5px;
                        .detail-info{
                            padding: 67px 98px;
                            background: url('./../../images/join-us-bg.png') no-repeat right bottom;
                            background-size: auto 60%;
                            height: inherit;
                            .detail-info-title{
                                font-size: 28px;
                                padding-left: 60px;
                                background-repeat: no-repeat;
                                background-position: left top;
                                background-size: auto 44px;
                                margin-bottom: 17px;
                            }
                            .detail-info-content-list{
                                font-size: 18px;
                                padding-left: 70px;
                                .content-item{
                                    line-height: 36px;
                                    position: relative;
                                    &::before{
                                        content: '.';
                                        font-weight: bold;
                                        position: absolute;
                                        left: -10px;
                                        top: -4px;   
                                    }
                                }
                            }
                        }
                    }
                    .detail-item-active{
                        position: absolute;
                        left: 248px;
                        height: 400px;
                        width: 594px;
                        border-radius: 5px;
                        .detail-info{                            
                            padding: 65px 98px;                          
                        }
                    }
                }
            }

            .dots-container{
                display: flex;
                align-items: center;
                justify-content: center;
                .dot{
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background: #DEE0E3;
                    margin-right: 12px;
                    &.active{
                        background: #FFDB00;
                    }
                }
            }
        }
    }
    .join-us-others{
        padding: 80px 0;
        .title{
            margin-bottom: 95px;
        }
        .list{
            display: flex;
            .list-item{
                width: 25%;
                text-align: center;       
                img{
                    width: 80px;
                    height: 80px;
                    margin-bottom: 30px;
                    cursor:pointer;
                    transition: all  0.3s;
                }
                img:hover{
                    // transform: rotateX(180deg);
                    // transform: rotateY(180deg);
                    // transform: rotate(30deg);
                    // rotate3d(x,y,z,30deg);
                    width: 120px;
                    height: 120px;
                }
            }
          
        }
    }
    .join-us-how{
        padding: 80px 0;
        background: url('./../../images/join-us-form-bg.png') no-repeat center;
        background-size: cover;
        .sub-title{
            text-align: center;
            margin: 24px 0 60px;
        }
        .join-us-form-container{
            height: 520px;
            display: flex;
            background: #fff;
            border-radius: 5px;
            .img-container{  
                img{
                    height: 520px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    cursor: pointer;
                    transition: all 0.6s;
                   
                    &:hover{
                        width: 489px;
                        height:530px;
                      
                    }
                }                         
            }
            .form-container{
                width: 720px;
                padding: 30px 117px;

                .ant-select{
                    width: 470px;
                    height: 44px; 
                }
                input, textarea, .ant-select-selector{
                    margin-left: 5px;
                    width: 470px;
                    height: 44px; 
                    background: #F5F5F5 !important;
                    border-radius: 5px;
                    border: none;
                    font-size: 16px;
                    color: #444;                
                    &:focus{
                        box-shadow: none;  
                    }
                }
                .ant-select-selection-item, .ant-select-selection-placeholder{
                    line-height: 44px; 
                }
                textarea{
                    height: 121px;
                }
                .submit-button{
                    background: #FFEB00; //rgb(255,232,0);
                    color: #4F4909;
                    height: 50px;
                    border: none;
                    border-radius: 25px;
                    padding: 0 25px;
                    font-size: 18px;
                    &:hover{
                        // background: rgba(255,232,0,0.8);
                        color: #0654a1;
                    }
                }
                
            }

        }
    }

}


@primary-color: #0654A1;