@dot-size: 8px;
.slides-container{
    // position: relative; 
    .slide-item-container{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 99;
      -webkit-transition:  display 1s ease;
      -moz-transition:  display 1s ease;
      -ms-transition:  display 1s ease;
      -o-transition:  display 1s ease;
      transition:  display 1s ease;
      &.active{
          display: block;
          z-index: 100;
      }
    }
    .dots-container,.navigation-container{
      z-index: 101;
      position: absolute;
      width: 100%;
      text-align: center;
    }
    .dots-container{
      bottom: 100px;
      .dot{
        display: inline-block;
        cursor: pointer;
        width: @dot-size;
        height: @dot-size;
        -webkit-border-radius: @dot-size;
        -moz-border-radius: @dot-size;
        border-radius: @dot-size;
        -webkit-transition: background 1s ease;
        -moz-transition: background 1s ease;
        -ms-transition: background 1s ease;
        -o-transition: background 1s ease;
        transition: background 1s ease;
        background: #DEE0E3;
        margin-right: 12px;
        &.active,&:hover{
          background: #FFDB00;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
@primary-color: #0654A1;