@header-height: 80px;

@font-face {
	font-family: 'EnNumberFont';
	src: url('./AcuminVariableConcept.otf');/*IE*/
	
}
.app-container{
  font-size: 16px;
  color: #444444;
  padding-top: 80px;
  &.app-lang-en{
    font-family: EnNumberFont;
    .about-us-sub-links{
      width: 200px !important;
      top: 82px !important;
      right: -61px !important;
    }
    .app-footer{
      .copy-right{
        width: 708px !important;
      }
      .wechat-app, .wechat-h5{
        margin-right: 20px !important;
        width: 136px !important;
      }  
      .wechat-h5{
        margin-right: 0 !important;
      }
    }
  }
  &.app-lang-zh{
    font-family: Noto Sans SC; //Source Han Sans CN;
    font-weight: 400;
  }
  .app-header{
    height: @header-height;
    box-shadow: 0 0 18px 0 rgb(83 83 96 / 28%);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1009;
    background: rgba(255,255,255,0.8);
    .header-content{
      margin: 0 auto;
      height: inherit;
      display: flex;
      align-items: center;
      .logo{
        width: 315px;
        height: @header-height;
        background: url('./images/lecun-logo.png') no-repeat left center;
        background-size:  auto 50%;
        cursor: pointer;
      }
      .navigation{
        width: 100%;
        >li{
          padding: 30px 0;
        }
        li{
          display: inline-block;
          margin-right: 55px;
          // line-height: @header-height;
          // height: @header-height;
          position: relative;
          .active-link-indicator{
            height: 4px;
            width: 100%;
            background: transparent;
            position: absolute;
            bottom: 3px;
          }
          a{
            color: #444444;
            &.active{
              color: #0654A1;
              
            }
            &:hover{
              color: #0654A1;
              &+.active-link-indicator{
                // background: #ffeb00; //#F8A158;
              }
            }
          }
          &.about-us{
            .about-us-sub-links{
              position: absolute;
              z-index: 120;
              width: 124px;
              background: #fff;
              display: none;
              top: 82px;
              right: -30px;
            }
            &:hover{
              .about-us-sub-links{
                display: block;
                li{
                  display: block;
                  padding: 10px 0 !important;
                  text-align: center;
                }
              }
            }
          }
          &.about-us{
            // .active-link-indicator{
            //   bottom: -29px;
            // }
          }
        }
      }
      .sub-menu-list{
        width: 110px;
        display: none;
        position: absolute;
        top: 22px;
        right: 0;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
        background: #fff;
        z-index: 110;
        li{
          display: block;
          padding: 10px 15px;
          margin-right: 0;
        }
        &.hide{
          display: none;
        }
        &.show{
          display: block;
        }
      } 
      .switch-lang{
        width: 150px;
        text-align: center;
        position: relative;
        cursor: pointer;
        padding: 20px 0;
        padding-right: 20px;
        .sub-menu-list{
          top: 50px;
          &.hide{
            display: none;
          }
          &.show{
            display: block;
          }
          li{
            &:hover{
              color: #0654A1;
            }
          }
          
        } 
        // .arrow-down{
        //   display: inline-block;
        //   border: 6px solid #000;
        //   border-color: transparent;
        //   border-top-color: #000;
        //   position: absolute;
        //   right: 0;
        //   bottom: 2px;
        // }
            
      }
      .user-icon{
        width: 36px;
        img{
          cursor: pointer;
        }
      }
    }
  }
  .page-content{
    min-height: 1000px;
  }
  .app-footer{
    height: 160px;
    background: #1D2645;
    color: #fff;
    display: flex;
    align-items: center;
    .footer-content{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      .company-phone{
        width: 200px;
        .phone-number{
          font-size: 28px;
          // cursor:pointer;
          // transition: all  0.3s;
          // &:hover{
          //     color: #0654A1;
          // }
        }
      }
      .copy-right{
        width: 740px;
        text-align: center;
      }
      .wechat-app, .wechat-h5{
        text-align: center;
        margin-right: 60px;
        width: 100px;
        border-radius: 5px;
        img{
          width: 75px;
          margin-bottom: 15px;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.3s;
        }
        img:hover{
          width: 85px;
          height: 85px;
      }
        .erweima{
          width: 75px;
          height: 75px;
          padding: 10px;
          margin-bottom: 15px;
        }
      }  
      .wechat-h5{
        margin-right: 0;
      }
    }
  }
}


@primary-color: #0654A1;