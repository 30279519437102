.company-intro{
    .page-banner{
        height: 690px;
        position: relative;
        .banner-text{
            height: inherit;
            color: #fff;
            text-align: center;
            padding: 100px 200px;
            >div{
               width: 100%; 
            }
            .title{
                font-size: 68px;
            }
            .sub-title{
                font-size: 22px;
                margin: 27px 0;

            }
            .intro-text{
                font-size: 18px;
                padding: 0 94px;
            }

        }
    }
    .config-bg{
        margin: 80px 0;
        .config-bg-container{
            height: 480px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .company-values{
        margin-bottom: 80px;
        .section-title{
            font-size: 28px;
            text-align: center;
            margin-bottom: 40px;
            margin-top: 30px;
            cursor:pointer;
            transition: all  0.3s;
            &:hover{
                color: #0654A1;
            }
        }
        .value-list{
            display: flex;
            .value-item{
                width: 386px;
                // height: 268px;
                height:248px;
                transform: scale(0.95);
                margin-right: 21px;
                margin-top:10px;
                // background: #ccc;
                border-radius: 5px;
                color: #fff;
                background-repeat: no-repeat;
                background-position: center;
                // background-size: cover;
                cursor: pointer;
                transition: all 0.3s;
                &:nth-child(3n){
                    margin-right: 0;
                }
                padding: 80px 30px;
                text-align: center;
                .title{
                    font-size: 26px;
                    margin-bottom: 24px;
                }
                .desc{
                    font-size: 20px;
                }
                &:hover{
                    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
                    // width: 400px;
                    // height:280px;
                    transform: scale(1);
                }
            }
        }
    }
}
@primary-color: #0654A1;